<template>
  <section
    class="text-bild part relative"
    :class="[
      payload.hintergrundfarbe,
      payload.isBlog ? 'blog' : '',
      payload.clipbottom ? 'clip' : '',
      fullImage ? 'contains-full-image' : '',
    ]"
  >
    <div
      v-if="payload.bildpositionvertikal.includes('top') && isDesktop"
      class="image-wrapper"
      :class="[payload.bildpositionvertikal, isLeft && 'left']"
    >
      <Image v-view class="image animate in-bottom" :src="payload.bild" />
    </div>
    <div v-view class="constrain animate in-bottom">
      <div class="row-12">
        <div
          class="text-container col-12 md:col-6"
          :class="{
            'md:offset-1': !isLeft,
            'md:order-2 md:ml-6': isLeft,
            'md:offset-4 md:col-7': isLeft && !payload.bild.id
          }"
        >
          <section v-html="payload.text" />
        </div>
        <div
          v-if="payload.bild.id && !fullImage"
          class="col-12 md:offset-1"
          :class="[isLeft ? 'md:order-1 md:col-4' : 'md:col-3' ]"
        >
          <div
            v-if="!payload.bildpositionvertikal.includes('top') || !isDesktop"
            class="image-wrapper"
            :class="[payload.bildpositionvertikal, isLeft && 'left']"
          >
            <a v-if="payload.bildurl" :href="payload.bildurl" target="_blank">
            <Image class="image" :src="payload.bild" />
            </a>
            <Image v-else class="image" :src="payload.bild" />
          </div>
        </div>
        <div
          class="text-container col-12 md:col-6 md:offset-1 mt-6"
          :class="{
            'md:order-2 md:o': isLeft,
            'md:offset-4 md:col-7': isLeft && !payload.bild.id
          }"
        >
          <section v-html="payload.textunten" />
        </div>
      </div>
    </div>
    <Image
      v-if="fullImage && isDesktop"
      v-view
      class="full-image animate in-bottom"
      :src="payload.bild"
    />
    <Image
      v-else-if="fullImage"
      v-view
      class="animate in-bottom"
      :payload="payload"
    />
    <text-clip-bottom
      v-if="payload.clipbottom"
      class="clipper"
      :class="[ payload.clipbottomcolor ]"
      :clip-bottom="payload.clipbottom"
      :clip-bottom-mobile="payload.clipbottommobile"
    />
  </section>
</template>

<script>
import { computed } from 'vue';
import Container from '../utils/Container.vue';
import TextClipBottom from '../utils/TextClipBottom.vue';
import { useWithinBreakpoint } from '@/composables/breakpoints';

export default {
  components: { Container, TextClipBottom },
  props: { payload: { type: Object, default: Object } },
  setup(props) {
    const isDesktop = useWithinBreakpoint('md');
    return {
      isLeft: computed(() => props.payload.bildpositionhorizontal.includes('left')),
      fullImage: computed(() => props.payload.optionen.includes('fullImage')),
      isDesktop,
    };
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/styles/_mixin.scss';

.clipper {
  @apply bg-white;

  &.orange {
    @apply bg-primary;
  }

  &.blau {
    @apply bg-blue;
  }
}

.text-bild {
  @apply bg-white;

  position: relative;
  z-index: 10;
  @include responsive('padding-top', px(40), px(137));
  @include responsive('padding-bottom', px(40), px(95));

  &.clip {
    @include responsive('padding-bottom', px(40), px(95));
    @include responsive('margin-bottom', px(100), px(300));

    &::after {
      @include responsive('height', px(100), px(300));
    }
  }

  &::after {
    content: "";
    position: absolute;
    width: 100%;
    top: 100%;
    z-index: -1;
    @include responsive('height', px(40), px(95));
  }

  &.orange::before,
  &.orange::after,
  &.orange {
    @apply text-dark-orange;
    @apply bg-primary;
  }

  &.blau::before,
  &.blau::after,
  &.blau {
    @apply bg-secondary;
  }

  &.blau + .text-bild.blau,
  &.orange + .text-bild.orange,
  &.clip + .text-bild,
  &.weiss + .text-bild.weiss {
    padding-top: 0;
  }

  &:first-child {
    @include responsive('padding-top', calc(var(--header-height) + #{px(24)}), px(137));
  }
}

:deep(h2) {
  margin-bottom: px(24);
}

.text-container {
  z-index: 2;
  :deep(p) {
    margin-bottom: 1rem;
  }
  :deep(ul) {
    margin: 0;
    list-style: '-';
    color: var(--color-gray-blue);
    margin-bottom: 1rem;
    li {
      margin-left: px(10);
      @include responsive('padding-left', px(16), px(75));

      &:not(:last-child) {
        @include responsive('margin-bottom', px(16), 0);
      }
    }
    .text-bild.orange &,
    .text-bild.blau & {
      color: currentColor;
    }
  }
  :deep(h3) {
    margin-bottom: 1rem;

    .text-bild.orange &,
    .text-bild.blau & {
      color: currentColor;
    }
  }
}

.image-wrapper {
  height: auto;
  width: 100%;
  position: relative;

  @include responsive('margin', px(32) auto, auto);
  @include responsive('max-width', 50%, 90%);

  img {
    @apply rounded-full;
    height: 100%;
    overflow: hidden;
    aspect-ratio: 1 / 1;
    object-fit: cover;
  }

  &.left {
    @include responsive('max-width', 50%, 90%);
  }

 &.bottom {
    height: auto;

    @screen md {
      max-width: initial;
      img {
        position: absolute;
        top: 100%;
        transform: translateY(-20%);
      }
    }
  }

  &.smallCentered {
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    margin: 0;
    @include responsive('max-width', 50%, 50%);
  }

  &.top {
    height: auto;

    @screen md {
      max-width: initial;
      img {
        position: absolute;
        height: auto;
        bottom: calc(100% + #{px(200)});
        left: calc(50% + var(--col-width) * 3.5);
        width: calc(var(--col-width) * 3);
        transform: translate(-50%, 50%);
        z-index: 100;
      }
    }
  }
}

.blog {
  :deep(h2) {
    @apply text-medium-blue;

    @include responsive('margin-bottom', px(24), px(60));
  }
  :deep(blockquote) {
    margin: px(86) 0;
  }
}

.full-image {
  top: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  object-fit: cover;
  clip-path: ellipse(150% 150% at 150% 70%);

  @include responsive('margin-left', auto, 0);
  @include responsive('width', 80vw, 40vw);
  @include responsive('position', relative, absolute);
  @include responsive('shape-outside', ellipse(150% 150% at 150% 70%), none);
}
</style>
